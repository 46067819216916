import type { QueryOptions } from "@tanstack/react-query";
import { makeApi } from "@zodios/core";
import { z } from "zod";

import { makeClientWithHooks } from "api/helpers";
import { type UserSetting, linkableAccountSchema, userSchema } from "api/resources/User";

import { ApiClient } from "./baseClient";

class UserSettingsClient extends ApiClient<UserSetting, false> {
  endpoint = () => "/user-settings";
}

export const userSettingsClient = new UserSettingsClient();

const managerProfileSchema = z.object({
  id: z.number(),
  business_name: z.string().nullish(),
  email: z.string(),
  market_id: z.number().nullish(),
  market_name: z.string().nullish(),
  referral_code: z.string().nullish(),
  has_locations: z.boolean().catch(() => false),
  manager_skill_id: z.number().nullish(),
});
export type ManagerProfile = z.infer<typeof managerProfileSchema>;

const basePath = "/users";
const authUserPath = `${basePath}/me/`;

const userApi = makeApi([
  {
    method: "get",
    path: authUserPath,
    response: userSchema,
    alias: "authUser",
  },
  {
    method: "put",
    path: authUserPath,
    response: userSchema,
    parameters: [{ name: "User", type: "Body", schema: userSchema }],
    alias: "updateAuthUser",
  },
  {
    method: "patch",
    path: authUserPath,
    response: userSchema,
    parameters: [{ name: "User", type: "Body", schema: userSchema.partial() }],
    alias: "partialUpdateAuthUser",
  },
  {
    method: "get",
    path: "/manager-profiles/me/",
    response: managerProfileSchema,
    alias: "authManager",
  },
  {
    method: "get",
    path: `${authUserPath}linkable-accounts/`,
    response: z.array(linkableAccountSchema),
    alias: "linkableAccounts",
  },
]);

export const { client, hooks } = makeClientWithHooks("users", userApi);

export const authUserQueryOptions: QueryOptions<z.infer<typeof userSchema>> = {
  queryKey: hooks.getKeyByAlias("authUser", {}),
  queryFn: client.authUser,
};

export const authManagerQueryOptions: QueryOptions<z.infer<typeof managerProfileSchema>> = {
  queryKey: hooks.getKeyByAlias("authManager", {}),
  queryFn: client.authManager,
};

import { requiredStringSchema } from "@gigpro/ui/Form";
import { type RefetchQueryFilters, type UseQueryOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import { type GigApplication, gigApplicationSchema } from "api/resources/gigApplications";
import { proBadgeSchema } from "api/resources/pros";
import { type PaginationResponse, makePaginatedResult } from "api/resources/resource";
import { z } from "zod";
import { gigCancellationSchema } from "./gigs";

import { browserTimezone } from "@gigpro/utils/datetime";
import { makeApi } from "@zodios/core";
import { makeClientWithHooks } from "api/helpers";
import type { QueryOptions } from "api/queries/types";
import type { StafferGig } from "api/resources/StafferGig";
import { groupApplications, postProcessServerApplication } from "utils/GigUtil";

export const ProDismissal = z.object({
  application: z.number(),
  explanation: requiredStringSchema,
  reason: z.enum([
    "BUSINESS_CALLED_OUT",
    "FEWER_PROS_NEEDED",
    "NO_SHOW",
    "OVERRIDE_AUTOSELECT",
    "STAFFER_DISMISSAL",
    "OTHER",
  ]),
  block_pro_data: gigCancellationSchema.shape.block_pro_data,
  rating: z.number().optional(),
  another_pro_needed: z.boolean().optional(),
});

export const gigAutoMatchApplicationSchema = z
  .object({
    id: z.number(),
    gigster: z.object({
      id: z.number(),
      display_name: z.string(),
      headshot_public_id: z.string(),
      badges: proBadgeSchema.nullable(),
      avg_rating: z.number().nullish(),
      gigs_worked: z.number().nullish(),
      completion_percentage: z.number().nullish(),
      skill_certification_count: z.number().nullish(),
    }),
  })
  .transform((data) => ({
    ...data,
    selection_source: "AUTO_MATCH",
    gigster: {
      ...data.gigster,
      review_count: data.gigster.gigs_worked,
      ratings_summary: [
        {
          is_overall_type: true,
          label: "Overall",
          name: "overall",
          rating_count: data.gigster.gigs_worked,
          avg_rating: data.gigster.avg_rating,
        },
      ],
      gigster_profile: {
        ...data.gigster,
        display_name: data.gigster.display_name,
        headshot_public_id: data.gigster.headshot_public_id,
      },
    },
  }));

export type GigAutoMatchApplication = z.infer<typeof gigAutoMatchApplicationSchema>;

export type ProDismissal = z.infer<typeof ProDismissal>;
export type DismissalReason = ProDismissal["reason"];

export enum ApplicationsSortOption {
  RecommendedDesc = "-recommended",
  LastUpdatedDesc = "-last_updated_ts",
  CancellationCreatedDesc = "-cancellation__created_ts",
  GigsWorkedDesc = "-gigster__review_summary__gigs_worked",
  AvgRatingDesc = "-gigster__review_summary__avg_rating",
  CompletionPercentageDesc = "-gigster__review_summary__completion_percentage",
}
const applicationsSortOptionSchema = z.nativeEnum(ApplicationsSortOption);

export type ApplicationsQueryPayload = NonNullable<
  Parameters<typeof hooks.getKeyByAlias<"openApplications">>[1]
>["queries"];

const applicationsQueryParameters = [
  { name: "gig_id", type: "Query", schema: z.number() },
  {
    name: "ordering",
    type: "Query",
    schema: applicationsSortOptionSchema.optional(),
  },
  { name: "previous_position", type: "Query", schema: z.boolean().optional() },
  { name: "has_worked_here", type: "Query", schema: z.boolean().optional() },
  { name: "pro_verified", type: "Query", schema: z.boolean().optional() },
  { name: "not_applied_applications", type: "Query", schema: z.boolean().optional() },
  { name: "has_applied_to_all_gigs_in_bundle", type: "Query", schema: z.boolean().optional() },
  { name: "favorite_pro", type: "Query", schema: z.boolean().optional() },
  { name: "badge_types", type: "Query", schema: proBadgeSchema.optional() },
  { name: "has_pro_skill_certification", type: "Query", schema: z.number().optional() },
  { name: "pro_certifications", type: "Query", schema: z.array(z.string()).optional() },
] as const;
const gigApplicationsPath = "/staffer/gig-applications/";
const gigApplicationsApi = makeApi([
  {
    method: "get",
    path: gigApplicationsPath,
    response: makePaginatedResult(gigApplicationSchema).or(z.array(gigApplicationSchema)),
    parameters: [
      ...applicationsQueryParameters,
      { name: "page", type: "Query", schema: z.number().optional() },
      { name: "page_size", type: "Query", schema: z.number().optional() },
    ],
    alias: "openApplications",
  },
  {
    method: "get",
    path: `${gigApplicationsPath}?not_applied_applications=true`,
    response: z.array(gigApplicationSchema),
    parameters: applicationsQueryParameters,
    alias: "notOpenApplications",
  },
  {
    method: "get",
    path: `${gigApplicationsPath}:id/`,
    response: gigApplicationSchema.omit({ match_score: true, review: true, cancellation: true, gigster: true }),
    alias: "application",
  },
  {
    method: "post",
    path: "/gig-applications-cancellations/",
    response: ProDismissal.omit({ application: true }),
    parameters: [
      {
        name: "Dismissal",
        type: "Body",
        schema: ProDismissal,
      },
    ],
    alias: "dismissPro",
  },
  {
    method: "get",
    path: "/gigs/:id/auto-matches/",
    response: makePaginatedResult(gigAutoMatchApplicationSchema),
    alias: "autoMatches",
  },
]);
const { client, hooks } = makeClientWithHooks("gigApplications", gigApplicationsApi);

export type ApplicationsQueryParams = Omit<ApplicationsQueryPayload, "gig_id"> & { gig?: StafferGig };

const useOpenApplications = (
  { gig, ...params }: ApplicationsQueryParams,
  options?: UseQueryOptions<PaginationResponse<GigApplication> | Array<GigApplication>>,
) => {
  const queryClient = useQueryClient();
  const finalParams = { ...params, gig_id: gig?.id ?? -1 };
  const { data, refetch, ...rest } = useQuery({
    queryKey: hooks.getKeyByAlias("openApplications", { queries: finalParams }),
    queryFn: () => client.openApplications({ queries: finalParams }),
    select: (data) => {
      if (!data)
        return {
          count: 0,
          next: null,
          previous: null,
          results: [],
        };
      if (Array.isArray(data)) {
        return {
          count: data.length,
          next: null,
          previous: null,
          results: data.map((application) =>
            postProcessServerApplication(application, gig?.location.timezone ?? browserTimezone),
          ),
        };
      }
      if (!data?.results) return { ...data, results: [] };
      return {
        ...data,
        results: data.results.map((application) =>
          postProcessServerApplication(application, gig?.location.timezone ?? browserTimezone),
        ),
      };
    },
    enabled: !!gig?.id,
    ...options,
  });
  const predicate: RefetchQueryFilters<PaginationResponse<GigApplication>>["predicate"] = (query) =>
    JSON.stringify(query.queryKey).includes("gig-applications");
  if (Array.isArray(data)) {
    return {
      data,
      count: data.length,
      next: null,
      prev: null,
      refetch: () => queryClient.refetchQueries({ predicate }),
      ...rest,
    };
  }
  return {
    data: data?.results,
    count: data?.count ?? 0,
    next: data?.next,
    prev: data?.previous,
    refetch: () => queryClient.refetchQueries({ predicate }),
    ...rest,
  };
};

const useNotOpenApplications = (
  { gig, ...params }: Omit<ApplicationsQueryParams, "page" | "page_size">,
  options?: QueryOptions<Array<GigApplication>, ReturnType<typeof groupApplications>>,
) => {
  const finalParams = { ...params, gig_id: gig?.id ?? -1 };
  const { isLoading, refetch, data } = useQuery({
    queryKey: hooks.getKeyByAlias("notOpenApplications", { queries: finalParams }),
    queryFn: () => client.notOpenApplications({ queries: finalParams }),
    select: (data) => {
      if (!data) return { cancelled: [], confirmed: [], pending: [] };
      return groupApplications(
        data.map((application) => postProcessServerApplication(application, gig?.location.timezone ?? browserTimezone)),
      );
    },
    enabled: !!gig?.id,
    ...options,
  });

  return {
    pending: data?.pending ?? [],
    confirmed: data?.confirmed ?? [],
    cancelled: data?.cancelled ?? [],
    isLoading,
    refetch,
  };
};

export { client, hooks, useOpenApplications, useNotOpenApplications };

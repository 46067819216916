import { z } from "zod";

import { PaymentStatus } from "./StafferGig";
import { proBadgeSchema, proSchema } from "./pros";
import { ratingTypeSchema } from "./ratings";
import { makeApiResource } from "./resource";

const timeSheetPunchSchema = z.object({
  id: z.string(),
  punch_type: z.enum(["CLOCK_IN", "CLOCK_OUT"]),
  time: z.string(),
  pro_coordinates: z.object({
    coordinates: z.array(z.number()),
    type: z.enum(["Point", "MultiPoint"]),
  }),
  pro_coordinates_precision: z.enum(["FINE", "COARSE"]).nullish(),
});

const timeSheetSchema = z.object({
  id: z.string(),
  application_id: z.number(),
  time_punches: z.array(timeSheetPunchSchema),
  has_extension: z.boolean(),
});

export const reviewSchema = z.object({
  application_id: z.number(),
  feedback: z.string().nullish(),
  ratings: z.array(ratingTypeSchema.extend({ rating: z.number() })),
});
export type Review = z.infer<typeof reviewSchema>;

export const gigApplicationSchema = makeApiResource({
  gig_id: z.number(),
  gigster: proSchema,
  is_cancelled: z.boolean(),
  is_selected: z.boolean(),
  is_confirmed: z.boolean(),
  is_blocked: z.boolean(),
  is_favorite: z.boolean().default(false).nullish(),
  has_applied_to_all_gigs_in_bundle: z.boolean().default(false).nullish(),
  match_score: z.coerce.number().nullish(),
  number_of_gigs_in_bundle_applied_to: z.number().optional(),
  number_of_gigs_in_bundle: z.number().optional(),
  review: reviewSchema.nullish(),
  cancellation: z
    .object({
      created_ts: z.string(),
      explanation: z.string(),
      reason: z.enum([
        "CANCELLED_BY_GIGPRO_ADMIN",
        "CANCELLED_BY_GIGPRO_LOCATION_MANAGER",
        "CANCELLED_BY_PRO",
        "PRO_SUSPENDED",
        "BUSINESS_CALLED_OUT",
        "CALLED_OUT",
        "FEWER_PROS_NEEDED",
        "NO_SHOW",
        "OVERRIDE_AUTOSELECT",
        "STAFFER_DISMISSAL",
        "OTHER",
      ]),
    })
    .nullish(),
  payment: makeApiResource({ status: z.nativeEnum(PaymentStatus) }).nullish(),
  working_hours: z.object({
    start_time: z.coerce.date().nullish(),
    end_time: z.coerce.date().nullish(),
    original_start_time: z.coerce.date().nullish(),
    original_end_time: z.coerce.date().nullish(),
  }),
  is_boosted: z.boolean(),
  badges: proBadgeSchema.nullish(),
  selection_source: z.enum(["STAFFER", "ADMIN", "AUTO_MATCH", "AUTO_DIRECT_OFFER"]),
  timesheet: timeSheetSchema.nullish(),
});

type TimeSheetPunchSchema = z.infer<typeof timeSheetPunchSchema>;
type TimeSheetSchema = z.infer<typeof timeSheetSchema>;
type GigApplication = z.infer<typeof gigApplicationSchema>;

export type { TimeSheetPunchSchema, TimeSheetSchema, GigApplication };

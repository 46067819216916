import { makeApi } from "@zodios/core";
import { z } from "zod";

import { makeClientWithHooks } from "api/helpers";
import { requirementSchema, skillSchema, skillWithDefaultsSchema } from "api/resources/skills";

const skillsApi = makeApi([
  {
    method: "get",
    path: "/skills/?query=ordered_by_popularity",
    response: z.array(skillSchema.extend({ group_name: z.string() })),
    alias: "skills",
  },
  {
    method: "get",
    path: "/skills/?include_defaults=true&include_recommendations=true",
    response: z.array(skillWithDefaultsSchema),
    alias: "skillsWithDefaults",
  },
  {
    method: "get",
    path: "/requirement-for-works/",
    response: z.array(requirementSchema),
    parameters: [{ name: "skill_id", type: "Query", schema: z.number().optional() }],
    alias: "requirements",
  },
  {
    method: "post",
    path: "/requirement-for-works/",
    response: requirementSchema,
    parameters: [{ name: "body", type: "Body", schema: requirementSchema.omit({ id: true }) }],
    alias: "createRequirement",
  },
]);

export const { client, hooks } = makeClientWithHooks("skills", skillsApi);

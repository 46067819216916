import { requiredStringSchema } from "@gigpro/ui/Form";
import { z } from "zod";

import { makeApiResource } from "./resource";

const competencyBaseSchema = makeApiResource({ name: z.string() });

export const skillSchema = makeApiResource({
  name: z.string(),
  description: z.string().nullish(),
  competencies: z.array(competencyBaseSchema).optional(),
});
export type Skill = z.infer<typeof skillSchema>;

export const skillWithDefaultsSchema = skillSchema.extend({
  default_description: z.string().nullish(),
  default_no_experience_required: z.boolean().nullish(),
  default_wage_amount: z.coerce.number().nullish(),
  recommended_wage_amount: z.coerce.number().nullish(),
  recommended_wage_amount_boosted: z.coerce.number().nullish(),
  minimum_wage_amount: z.coerce.number().nullish(),
});
export type SkillWithDefaults = z.infer<typeof skillWithDefaultsSchema>;

export const competencySchema = competencyBaseSchema.extend({ skill: skillSchema });

export const requirementSchema = makeApiResource({
  name: requiredStringSchema,
  category: z.string(),
  skill: z.number().nullish(),
});

export type Requirement = z.infer<typeof requirementSchema>;

import type Stripe from "stripe";

import { phoneNumberSchema, postalCodeSchema, requiredStringSchema } from "@gigpro/ui/Form";
import { makeApi } from "@zodios/core";

import { z } from "zod";

import { browserTimezone } from "@gigpro/utils/datetime";
import { makeClientWithHooks } from "api/helpers";
import { makePaginatedResult } from "api/resources/resource";
import { useFeatureFlag } from "providers/LaunchDarkly";
import { useActiveOrganization } from "services/locationOrgContext";
import { makeIdToCloudinaryUrl } from "utils/cloudinary";

export const billingSourceSchema = z.enum(["LOCATION", "ORGANIZATION"]);
export type BillingSource = z.infer<typeof billingSourceSchema>;
const approvalStatusSchema = z.enum(["APPROVED", "PENDING", "DENIED"]);
export type ApprovalStatus = z.infer<typeof approvalStatusSchema>;

export const locationPayloadSchema = z.object({
  id: z.coerce.number(),
  name: requiredStringSchema,
  market_id: z.number(),
  market_name: z.string().optional(),
  organization_id: z.string().nullish(),
  image_public_id: z.string().nullish(),

  street_address: requiredStringSchema,
  city: requiredStringSchema,
  state_code: requiredStringSchema,
  zip_code: postalCodeSchema,
  phone_number: phoneNumberSchema,
  url: z.string().nullish(),
  approval_status: approvalStatusSchema.nullish(),
  billing_source: billingSourceSchema.nullish(),
  invoicing_enabled: z.boolean().nullish(),
  concierge_enabled: z.boolean().nullish(),
  background_checks_enabled: z.boolean(),
  is_checking_required: z.boolean().nullish(),
  immediate_auto_select_enabled: z.boolean().nullish(),
});

export type LocationPayload = z.infer<typeof locationPayloadSchema>;

const locationBaseSchema = locationPayloadSchema.omit({ organization_id: true }).extend({
  organization_id: z.string(),
  approval_status: approvalStatusSchema,
  team_managers_count: z.number(),
  timezone: z.string().catch(browserTimezone),

  billing_source: billingSourceSchema,
  stripe_payment_method_id: z.string().nullish(),

  coordinates: z
    .object({
      type: z.literal("Point"),
      coordinates: z.tuple([z.number(), z.number()]),
    })
    .nullish(),
});

const locationSchema = locationBaseSchema.transform((location) => ({
  imageUrl: makeIdToCloudinaryUrl(location.image_public_id),
  ...location,
}));
export type Location = z.infer<typeof locationSchema>;

const minimalLocationSchema = locationBaseSchema
  .pick({ id: true, name: true, organization_id: true, image_public_id: true })
  .transform((location) => ({ imageUrl: makeIdToCloudinaryUrl(location.image_public_id), ...location }));
export type MinimalLocation = z.infer<typeof minimalLocationSchema>;

const minimalOrgLocationItem = locationBaseSchema.pick({
  id: true,
  name: true,
  image_public_id: true,
  team_managers_count: true,
});

const organizationSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    image_public_id: z.string().nullish(),
    locations: z.array(minimalOrgLocationItem).optional().nullish(),
  })
  .transform((org) => ({ imageUrl: makeIdToCloudinaryUrl(org.image_public_id), ...org }));

export type Organization = z.infer<typeof organizationSchema>;
export const organizationUpdateSchema = z.object({
  name: z.string(),
  image_public_id: z.string().nullish().optional(),
});

export type OrganizationUpdate = z.infer<typeof organizationUpdateSchema>;

const orgBillingInfoSchema = z.object({
  external_id: z.string().nullish(),
  type: z.enum(["card", "us_bank_account"]).nullish(),
  status: z.enum(["REQUIRES_ACTION", "ACTIVE", "FAILED", "NOT_CONFIGURED"]),
  details: z.custom<Stripe.PaymentMethod>().nullish(),
});
export type OrgBillingInfo = z.infer<typeof orgBillingInfoSchema>;

const locationsAndOrganizationsApi = makeApi([
  // Locations
  {
    method: "get",
    path: "/users/me/active-locations/",
    parameters: [
      {
        type: "Query",
        name: "organization_id",
        schema: z.string().optional(),
      },
    ],
    response: z.array(minimalLocationSchema),
    alias: "activeLocations",
  },
  {
    method: "get",
    path: "/users/me/approved-locations/",
    parameters: [
      {
        type: "Query",
        name: "organization_id",
        schema: z.string().optional(),
      },
    ],
    response: z.array(minimalLocationSchema),
    alias: "approvedLocations",
  },
  {
    method: "post",
    path: "/locations/",
    parameters: [
      {
        type: "Body",
        name: "location",
        schema: locationPayloadSchema.partial(),
      },
    ],
    response: locationSchema,
    alias: "createLocation",
  },
  {
    method: "patch",
    path: "/locations/:id/",
    parameters: [
      {
        type: "Body",
        name: "location",
        schema: locationPayloadSchema.partial(),
      },
    ],
    response: locationBaseSchema.pick({ id: true, image_public_id: true, billing_source: true, approval_status: true }),
    alias: "updateLocation",
  },
  {
    method: "get",
    path: "/locations/:locationId/",
    response: locationSchema,
    alias: "location",
  },
  {
    method: "get",
    path: "/locations/?query=managed",
    parameters: [
      {
        type: "Query",
        name: "market_id",
        schema: z.number().optional(),
      },
      {
        type: "Query",
        name: "organization_id",
        schema: z.string().optional(),
      },
      {
        type: "Query",
        name: "approval_status",
        schema: approvalStatusSchema.optional(),
      },
    ],
    response: makePaginatedResult(locationSchema).transform((data) => data.results),
    alias: "locations",
  },
  {
    method: "get",
    path: "/locations/:locationId/setup-intent/",
    response: z.object({ client_secret: z.string() }),
    alias: "locationPaymentSetupIntent",
  },
  // Organizations
  {
    method: "get",
    path: "/staffer/organizations/?managed=true",
    response: z.array(organizationSchema),
    alias: "organizations",
  },
  {
    method: "get",
    path: "/staffer/organizations/:orgId/",
    response: organizationSchema,
    alias: "getOrganization",
  },
  {
    method: "get",
    path: "/staffer/organizations/:orgId/payment-method/",
    response: orgBillingInfoSchema,
    alias: "orgPaymentMethod",
  },
  {
    method: "get",
    path: "/staffer/organizations/:orgId/setup-intent/",
    response: z.object({ client_secret: z.string() }),
    alias: "orgPaymentSetupIntent",
  },
  {
    method: "patch",
    path: "/staffer/organizations/:orgId/",
    response: organizationUpdateSchema,
    parameters: [
      {
        type: "Body",
        name: "organization",
        schema: organizationUpdateSchema,
      },
    ],
    alias: "updateOrganization",
  },
]);

export const { client, hooks } = makeClientWithHooks("organizations", locationsAndOrganizationsApi);
export const { client: locationOrgClient, hooks: locationOrgHooks } = { client, hooks };

type UseContextLocationParams = {
  includeAll?: boolean;
};

export const useContextLocations = ({ includeAll }: UseContextLocationParams = {}) => {
  const showUnapproved = useFeatureFlag("unapproved-active-locations");
  const orgsEnabled = useFeatureFlag("temp-organizations");
  const activeOrgId = useActiveOrganization()?.id;

  const allLocations = locationOrgHooks.useActiveLocations(
    {},
    { enabled: showUnapproved, staleTime: Number.POSITIVE_INFINITY },
  );
  const approvedLocations = locationOrgHooks.useApprovedLocations(
    {},
    { enabled: !showUnapproved, staleTime: Number.POSITIVE_INFINITY },
  );

  const result = showUnapproved ? allLocations : approvedLocations;

  const orgs = locationOrgHooks.useOrganizations(undefined, {
    staleTime: Number.POSITIVE_INFINITY,
  });

  return {
    ...result,
    data:
      orgsEnabled && !includeAll && activeOrgId ?
        result.data?.filter((location) => location.organization_id === activeOrgId)
      : result.data,
    refetch: () => Promise.all([result.refetch(), orgs.refetch()]),
  };
};

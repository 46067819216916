import axios from "axios";

import { getActiveLocationId } from "services/globalState";

import { hooks as directOfferHooks } from "./clients/directOffer";
import { proFavoriteApi } from "./clients/favoritePros";
import { findAProApi } from "./clients/findAPro";
import { hooks as geoLogHooks } from "./clients/geoLogs";
import { hooks as gigApplicationHooks } from "./clients/gigApplications";
import { client as templateClient, hooks as templateHooks } from "./clients/gigPostTemplates";
import { client as gigproCertificatesClient, hooks as gigproCertificatesHooks } from "./clients/gigproCertificates";
import { hooks as stafferGigHooks } from "./clients/gigs";
import { hooks as hireHooks } from "./clients/hire";
import { managersSkillsApi } from "./clients/legacy/ManagerSkills";
import { locationAddOnApi } from "./clients/locationAddOns";
import { hooks as locationOrgHooks } from "./clients/locationsAndOrganizations";
import { chatTokenHooks, hooks as messagingHooks } from "./clients/messaging";
import { proGroupsApi } from "./clients/proGroups";
import { hooks as proHooks } from "./clients/pros";
import { hooks as recurrenceHooks } from "./clients/recurrence";
import { hooks as skillsHooks } from "./clients/skills";
import { client as usersClient, hooks as usersHooks } from "./clients/users";
import { GIGPRO_LOCATION_HEADER } from "./constants";
import { makeClientWithHooks } from "./helpers";

axios.interceptors.request.use((config) => {
  const locationId = getActiveLocationId();
  if (locationId) config.headers[GIGPRO_LOCATION_HEADER] = config.headers[GIGPRO_LOCATION_HEADER] ?? locationId;
  return config;
});

const { client, hooks } = makeClientWithHooks("gigpro", [
  ...locationAddOnApi,
  ...findAProApi,
  ...proGroupsApi,
  ...managersSkillsApi,
  ...proFavoriteApi,
] as const);

export {
  client as apiClient,
  hooks as apiHooks,
  usersHooks,
  stafferGigHooks,
  hireHooks,
  messagingHooks,
  chatTokenHooks,
  templateClient,
  templateHooks,
  directOfferHooks,
  skillsHooks,
  proHooks,
  gigApplicationHooks,
  recurrenceHooks,
  locationOrgHooks,
  geoLogHooks,
  gigproCertificatesHooks,
  gigproCertificatesClient,
  usersClient,
};

import "./index.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { initBraze } from "utils/braze";
import { initNewRelic } from "utils/newrelic";
import { Router } from "./routing/router";

initBraze();
initNewRelic();

// biome-ignore lint/style/noNonNullAssertion: root node definitely exists
const root = createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
);

import type { StafferGigApplication } from "./StafferGigApplication";
import type { GigApplication } from "./gigApplications";
import type { Gig } from "./gigs";
import type { LocationAddress } from "./locationAddress";
import type { ApiResource } from "./resource";
import type { Requirement } from "./skills";

export enum GigStatus {
  /**
   *  posted from an unapproved location
   */
  UNAPPROVED = "UNAPPROVED",
  /**
   *  cancelled (whether finished or not)
   */
  CANCELLED = "CANCELLED",
  /**
   *  hasn't finished, nobody confirmed
   */
  POSTED = "POSTED",
  /**
   *  hasn't finished, pro confirmed
   */
  CONFIRMED = "CONFIRMED",
  /**
   *  finished, nobody confirmed
   */
  UNFILLED = "UNFILLED",
  /**
   *  finished, confirmed, not completed
   */
  INCOMPLETE = "INCOMPLETE",
  /**
   *  finished, confirmed, completed
   */
  COMPLETED = "COMPLETED",
}

export enum GigType {
  /**
   * A classic gig open to the gigpro Marketplace
   */
  MARKETPLACE = "MARKETPLACE",
  /**
   * A private gig offered to a specific pro in the Marketplace
   */
  PRIVATE_DIRECT_OFFER = "PRIVATE_DIRECT_OFFER",
  /**
   * A hire interview
   */
  INTERVIEW = "INTERVIEW",
}

export enum PaymentStatus {
  /**
   * Payment is available to Pro
   */
  AVAILABLE = "AVAILABLE",
  /**
   * The payment failed due to an issue with the location payment method
   */
  ERROR = "ERROR",
  /**
   * the payment failed to process
   */
  FAILED = "FAILED",
  /**
   * GIGPRO submitted the payment to Stripe, but transfer is pending
   */
  PENDING_TRANSFER = "PENDING_TRANSFER",
  /**
   * Business has submitted payment to GIGPRO
   */
  SUBMITTED = "SUBMITTED",
}

export type LocationSummary = Gig["location"];

export type GigSettingKeys = keyof Pick<StafferGig, "is_checkin_required" | "auto_select_enabled">;
export type GigSettings = {
  [key in GigSettingKeys]: {
    display_name: string;
    description: string;
  };
};

export type StafferGig = ApiResource & {
  address_line_1: string;
  state_code: string;
  city: string;
  zip_code: string;
  use_location_address: boolean;
  contact_name: string;
  contact_phone: string;
  created_ts: Date;
  description: string;
  location_address: LocationAddress | null;
  location_id: number;
  location: LocationSummary;
  no_experience_required: boolean;

  skill: string;
  skill_id: number;
  special_inst?: string | null;
  start_time: Date;
  end_time: Date;
  status: GigStatus;
  wage_amount: number;
  requirements?: Array<Requirement>;
  background_check_required?: boolean;
  background_checks_fee_percentage?: number | null;
  chat_token?: string | null;
  bundle_id?: number | null;
  number_of_gigs_in_bundle?: number | null;
  boosted_gig_bonus?: number;
  is_recurring?: boolean;
  auto_select_enabled?: boolean;
  is_checkin_required?: boolean;

  allowed_confirmed_pros: number;
  number_of_applications: number;
  number_of_open_applications: number;
  number_of_selected_applications: number;
  number_of_confirmed_applications: number;
  number_of_cancellations: number;
  number_of_payments: number;
  number_of_failed_payments: number;
  number_of_error_payments: number;
  number_of_submitted_payments: number;
  number_of_auto_matched_pros: number;
  number_of_paid_pros: number;
  public_headshots: Array<string>;
  settings: GigSettings;
  type: GigType;
  checkin_code: string;
};

export interface StafferGigV2 extends ApiResource {
  address_line_1: string;
  state_code: string;
  city: string;
  zip_code: string;
  use_location_address: boolean;
  contact_name: string;
  contact_phone: string;
  created_ts: Date;
  description: string;
  location_id: number;
  location: LocationSummary;
  location_address: LocationAddress | null;
  no_experience_required: boolean;

  skill: string;
  skill_id: number;
  special_inst?: string | null;
  start_time: Date;
  end_time: Date;
  status: GigStatus;
  wage_amount: string;
  background_check_required?: boolean;
  background_checks_fee_percentage?: number | null;
  chat_token?: string | null;
  bundle_id?: number | null;
  number_of_gigs_in_bundle?: number | null;
  boosted_gig_bonus?: number;
  is_recurring?: boolean;
  allowed_confirmed_pros: number;
  auto_select_enabled?: boolean;
  stats: {
    id: number;
    total_applications_count: number;
    selected_applications_count: number;
    confirmed_auto_matched_pros_count: number;
    confirmed_pros_count: number;
    cancelled_applications_count: number;
    total_payments_count: number;
    failed_payments_count: number;
    error_payments_count: number;
    submitted_payments_count: number;
    paid_pros_count: number;
    gig: number;
  } | null;
  headshots: Array<string>;
  settings: GigSettings;
  checkin_code: string;
  type: GigType;
}

export type StafferGigsWithoutStats = Omit<
  StafferGig,
  | "number_of_applications"
  | "number_of_open_applications"
  | "number_of_selected_applications"
  | "number_of_confirmed_applications"
  | "number_of_cancellations"
  | "number_of_payments"
  | "number_of_failed_payments"
  | "number_of_error_payments"
  | "number_of_submitted_payments"
  | "number_of_paid_pros"
  | "public_headshots"
> & {
  applications: Array<StafferGigApplication>;
};

export interface ConfirmedPro extends ApiResource {
  applicationId: number;
  applicationReview?: GigApplication["review"];
  headshot_public_id?: string | null;
  fullName: string;
  first_name: string;
  last_name: string;
  reviewCount?: number | null;
  rating?: number | null;
  is_blocked: boolean;
  is_favorite: boolean;
  working_hours: GigApplication["working_hours"];
  payment?: GigApplication["payment"];
  review?: GigApplication["review"];
}
